<template>
  <div class="writing-page">
    <xmas-banner v-if="$store.getters.featureSwitchesState.XMAS_DISCOUNT"></xmas-banner>
    <cny-banner v-if="$store.getters.featureSwitchesState.CNY_DISCOUNT"></cny-banner>
    <header class="full_image">
      <h1>Paint the Full Picture</h1>
      <p>
        Connect the dots between your ancestors and the changing tides of history. We help you weave a complete tapestry
        of the intertwining stories hidden in your records.
      </p>
      <div class="header_actions">
        <mcr-buttons-row>
          <!--<mcr-button-router-link-to-pr label="Book a Call"></mcr-button-router-link-to-pr>-->
          <mcr-button-router-link-to-pr toStep="email" label="Get a Quote"></mcr-button-router-link-to-pr>
        </mcr-buttons-row>
      </div>
      <img :src="$getAsset('/assets/services/writing/Ancestor-Mosaic.jpg')" />
    </header>
    <div class="readable_content">
      <p class="intro">A tombstone might only state how long your grandfather lived.</p>
      <p>
        But combine his birthdate with a passenger list, and now we know that he was only 17 when he boarded a ship
        bound for San Francisco.
      </p>
      <p>
        Pull a page from your clan book about a Chinese Civil War battle that erupted near his village, shortly before
        he emigrated – and now, we can begin to understand just why he left China, never to return.
      </p>
      <p>
        Unfold a remittance letter he sent with savings back home. A careful read between the lines will unearth the
        opportunity he’s found, cooking up your inheritance one bowl of noodles at a time in the chop-suey kitchens of
        Chinatown.
      </p>
      <p>
        Sprinkle in a few sentences from his county gazetteer about traditional dishes he might have missed and tried to
        recreate... and now, you can taste the bittersweet, beautiful journey behind the flavors you’ve savored around
        your family table for years.
      </p>
      <p>
        We specialize in piecing together a mosaic of sources to paint a complete picture of your family history. Every
        single trace is a unique doorway to an incredible chapter of your backstory. We can’t wait to help you write
        yours.
      </p>

      <h4>What You Get</h4>
      <p>
        A timeless <strong>legacy website</strong> that resurrects and preserves your family's journey on the stage of
        world history. Richly illustrated with photos and maps, this modern time capsule makes centuries of stories easy
        to read and share with the whole family for generations to come.
      </p>
      <p>
        <strong><a href="/samples/zhang-odyssey/" target="_blank">Explore our live demo &#10142;</a></strong>
      </p>

      <div class="auto-video">
        <a href="/samples/zhang-odyssey/" target="_blank">
          <video loop muted autoplay :poster="$getAsset('/assets/services/writing/demo.jpg')">
            <source
              :src="this.$getAsset('/assets/services/writing/family-website-demo-clan-chronicles.mp4')"
              type="video/mp4"
            />
            <source
              :src="this.$getAsset('/assets/services/writing/family-website-demo-clan-chronicles.webm')"
              type="video/webm"
            />
          </video>
        </a>
      </div>
      <h4>How It Works</h4>
      <div class="sub-title">Share Your Stories with Us</div>
      <p>
        <mcr-button-router-link-to-pr
          :is-button="false"
          toStep="email"
          label="Leave us your email"
        ></mcr-button-router-link-to-pr>
        to receive a free consultation. Share the anecdotes and source materials you have about your ancestry and we'll
        be happy to provide a free assessment of further research we could conduct on your roots. We will then draft you
        a quote suited to your needs.
      </p>
      <div class="sub-title">We Paint the Full Picture</div>
      <p>
        Our history detectives and web designers join forces to research and write your dream family memoir,
        synthesizing the raw data of your records to recreate the real-life adventures of your ancestors.
      </p>
      <div class="sub-title">Celebrate Your Family Legacy</div>
      <p>
        Treasure your journey as a family, giving future generations the gift of knowing who they are – and the
        opportunity to add more stories as their paths unfold!
      </p>

      <h4>What to Expect</h4>
      <ul>
        <li><strong>Duration:</strong> Production typically lasts 2-5 months</li>
        <li><strong>Support:</strong> Dedicated MCR research and writing team</li>
        <li><strong>Hosting:</strong> Private website</li>
        <li><strong>Pricing:</strong> Determined on a case-by-case basis</li>
      </ul>
    </div>

    <prospect-request-end-actions>Paint the full picture of your past.</prospect-request-end-actions>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

import cnyBanner from '@/components/common/promotions/cnyBanner';
import xmasBanner from '@/components/common/promotions/xmasBanner';
import ProspectRequestEndActions from '@/components/common/prospectRequestEndActions';

export default {
  metaInfo: {
    title: 'Storytelling Services',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'We contextualize your past by integrating your family stories with local gazetteer history and broader Chinese culture, resulting in beautifully illustrated legacy sites.',
      },
    ],
  },
  components: {xmasBanner, cnyBanner, McrButtonRouterLinkToPr, ProspectRequestEndActions, mcrButtonsRow},
};
</script>

<style scoped lang="scss">
@import '@common/style/mixins';

header {
  .header_actions {
    margin-bottom: 40px;
    @media only screen and (max-width: $breakpoint-mobile) {
      margin-bottom: 20px;
    }
  }
}

.sub-title {
  font-weight: bold;
  margin-top: 32px;
}

.readable_content {
}
</style>
