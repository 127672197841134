<template>
  <div class="translation-page">
    <xmas-banner v-if="$store.getters.featureSwitchesState.XMAS_DISCOUNT"></xmas-banner>
    <cny-banner v-if="$store.getters.featureSwitchesState.CNY_DISCOUNT"></cny-banner>
    <header class="full_image">
      <h1>Translate Your Records</h1>
      <p>Decode your family documents and heirlooms to understand the facts and faces of your past.</p>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr label="Get in touch"></mcr-button-router-link-to-pr>
          <book-call-pr-button></book-call-pr-button>
        </mcr-buttons-row>
      </div>
      <img
        :data-src="$getAsset('/assets/services/translation/Knolling-Records-web-2.jpg')"
        class="lazyload"
        width="2400"
        height="1500"
      />
    </header>
    <div class="readable_content">
      <h4>What We Translate</h4>
      <ul>
        <li><router-link :to="{name: 'zupu-translation-services'}">Family tree books</router-link></li>
        <li>Handwritten letters</li>
        <li>Photo captions</li>
        <li>Ancestral tablets</li>
        <li>Tombstones</li>
        <li>Land deeds</li>
        <li>Books, magazines, or newspapers</li>
        <li>And more!</li>
      </ul>

      <h4>Pricing</h4>
      <p>Rates start at US$0.19 per Chinese character and depend on factors including:</p>
      <ul>
        <li>Document length</li>
        <li>Photo or scan quality</li>
        <li>Typed vs. handwritten characters</li>
        <li>Simplified vs. traditional characters</li>
        <li>Modern vs. Classical Chinese grammar</li>
      </ul>

      <h4>Storytelling<span class="new-badge">NEW!</span></h4>
      <p>
        Connect the dots between your ancestors and the changing tides of history. We help you weave a complete tapestry
        of the intertwining stories hidden in your records.
      </p>
      <router-link :to="{name: 'storytelling'}">
        <p><strong>Learn more about our storytelling packages</strong></p>
        <img :src="$getAsset('/assets/about/portraitmosaic.jpg')" />
      </router-link>

      <h4>Samples</h4>
      <p>
        Our multilingual team will provide high quality English translations of your Chinese records in a digital
        report, rendered with careful attention to tone and historical context.
      </p>
      <div class="samples">
        <div class="sample">
          <router-link to="/report/demo/lanfangzupu" target="_blank">
            <img :src="$getAsset('/assets/services/translation/screen_lanfang.jpg')" />
            <p><strong>Family Tree Book (Zupu):</strong> 500 Years of the Lanfang Li Clan</p>
          </router-link>
        </div>
        <div class="sample">
          <router-link to="/report/demo/gazetteer" target="_blank">
            <img :src="$getAsset('/assets/services/translation/screen_gazetteer.jpg')" />
            <p><strong>Gazetteer:</strong> Culture & Migration in Shaxi, Chungshan</p>
          </router-link>
        </div>
        <div class="sample">
          <a
            :href="$getAsset('/assets/services/translation/MyChinaRoots-Zupu-Translation Table-of-Contents-SAMPLE.pdf')"
            target="_blank"
          >
            <img :src="$getAsset('/assets/services/translation/screen_zupu.jpg')" />
            <p><strong>Zupu Menu:</strong> Sections we can typically translate from your Family Tree Book</p>
          </a>
        </div>
        <div class="sample">
          <a :href="$getAsset('/assets/services/translation/MyChinaRoots-Family-Tree-SAMPLE.pdf')" target="_blank">
            <img :src="$getAsset('/assets/services/translation/screen_tree.jpg')" />
            <p><strong>Family Tree:</strong> Descendants of Huang Guoxian</p>
          </a>
        </div>
      </div>
    </div>

    <div class="readable_content additional_panel">
      <h4>Related Services</h4>
      <ul>
        <li>
          <p><router-link :to="{name: 'zupu-translation-services'}">Zupu Translation</router-link></p>
          <p>We help you extract the stories and facts from your family tree book, going back hundreds of years.</p>
        </li>
        <li>
          <p>
            <router-link :to="{name: 'name-translation'}">Romanized English to Chinese Name Translation</router-link>
          </p>
          <p>We translate your ancestor's romanized English name into Chinese characters.</p>
        </li>
      </ul>
    </div>

    <prospect-request-end-actions>
      Get in touch to translate your records.
      <span slot="email-text">Email your files to <email-mcr-link-container></email-mcr-link-container></span>
    </prospect-request-end-actions>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

import BookCallPrButton from '@/base/elements/buttons/BookCallPrButton';

import EmailMcrLinkContainer from '@/components/common/emailMcrLinkContainer';
import cnyBanner from '@/components/common/promotions/cnyBanner';
import xmasBanner from '@/components/common/promotions/xmasBanner';
import ProspectRequestEndActions from '@/components/common/prospectRequestEndActions';

export default {
  metaInfo: {
    title: 'Translation Services',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'We translate your family trees, handwritten letters, zupus, and other records from Chinese to English. Preserve your family’s treasures today.',
      },
    ],
  },
  name: 'translation.page.vue',

  components: {
    EmailMcrLinkContainer,
    McrButtonRouterLinkToPr,
    ProspectRequestEndActions,
    mcrButtonsRow,
    BookCallPrButton,
    xmasBanner,
    cnyBanner,
  },
};
</script>

<style scoped lang="scss">
header {
  .header_actions p {
    margin-bottom: 0;
  }
}

.readable_content {
  padding-top: 0;
  img {
    box-shadow: $box-shadow;
  }
}

.samples {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 10px;

  .sample {
    width: calc(50% - 20px);
    margin-bottom: 20px;
    img {
      border: 1px solid $divider-line-color;
      display: block;
      border-radius: 4px;
    }
    p {
      color: $text-color;
    }

    &:hover {
      img {
        border: 1px solid $link-color;
      }
      p {
        color: $link-color;
        text-decoration: none;
      }
    }
    @media only screen and (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }
}

.additional_panel {
  a {
    font-weight: bold;
  }
}
</style>
